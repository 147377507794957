<template>
  <div class="page-wrapper">
    <a href="#main" class="skip" aria-label="Skip to main content">Skip to main content</a>
    <slot name="announcement" />
    <slot name="signup" />
    <slot name="header" />
    <slot />
    <slot name="footer" />
  </div>
</template>

<script setup lang="ts">
import { useCustomerStore } from '~/store/customer';
import { useDyStore } from '~/store/dy';
import { useConnectionStore } from '~/store/connection';

await useServerInit(); // i18n called here

const customerStore = useCustomerStore();
const route = useRoute();
const { getConnection } = useConnectionStore();
const { iterateBackCompatDecisions } = useDyStore();

const state = reactive({
  busEmitted: false,
});

const loggedIn = computed(() => customerStore.loggedIn);

onBeforeMount(async () => {
  await useCartBCValidate();
  await useIsLoggedIn();
  await useGetCart();
  await getConnection();
  // // !!!! IMPORTANT - SET GWP TEST AFTER "GETCART" to avoid dom issues on cart page
  await iterateBackCompatDecisions(); // TODO: put into process.server in setup after verifying no side-effects
});

watch([route, loggedIn], ([newRoute], [oldRoute]) => {
  if (newRoute !== oldRoute) {
    state.busEmitted = false;
  }
});
</script>

<style lang="scss" scoped>
:deep(.content-wrapper) {
  @include local-mixins.desktop {
    width: 100%;
    max-width: calc(90.75rem - 2rem); // 1452px - 32px for padding
  }
}
.skip {
  position: absolute;
  left: -10000px;
  top: auto;
  width: 1px;
  height: 1px;
  overflow: hidden;
  &:focus {
    position: static;
    width: auto;
  }
}
.dy-topbanner {
  position: sticky;
  top: 0;
  z-index: 3;
}
</style>
